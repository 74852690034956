// NOTE: this is used to provide default value for object-format component data with multiple level keys, like: config
import { defaultsDeep, get } from "lodash";
const deepGet = get;

function isPureObject(value) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

export default function(objectWithDefaults, dataModel) {
  Object.keys(objectWithDefaults).forEach(key => {
    if (isPureObject(objectWithDefaults[key])) {
      objectWithDefaults[key] = defaultsDeep(deepGet(dataModel, key, {}), objectWithDefaults[key]);
    } else {
      objectWithDefaults[key] = deepGet(dataModel, key, objectWithDefaults[key]);
    }
  })

  return objectWithDefaults
}
